import React from "react";
import styled from "styled-components";
import { useUserSelector } from "../data/userSlice";
import { Activities } from "./Activities";
import { Layout } from "./Layout";

const Root = styled.div`
  padding: 12px;
  padding-top: 2rem;
  text-align: center;
  overflow-x: auto;
  height: 100%;
  box-sizing: border-box;
`;

export const Home = () => {
  const user = useUserSelector();
  if (!user.isLoaded) return null;
  return (
    <Layout>
      <Root>
        <Activities />
      </Root>
    </Layout>
  );
};
