import React, { useState } from "react";
import styled from "styled-components";
import { colours } from "../../../styles/colours";
import { useForm } from "react-hook-form";
import { useUserStateSelector } from "../../../data/userStateSlice";
import { dbClient } from "../../../firebase/authClient";
import { nanoid } from "nanoid";
import ReactModal from "react-modal";

const Root = styled.div``;

const LinkButton = styled.button`
  color: ${colours.primary[8]};
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: "Metropolis-Regular", arial, sans-serif;
  text-decoration: underline;
  cursor: pointer;
`;

export const TrackNewActivity = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userState = useUserStateSelector();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm();

  const submitNewActivity = async ({ name }) => {
    const newId = nanoid();
    const newUserState = {
      ...userState,
      activityIds: [...userState.activityIds, newId],
      activitiesById: {
        ...userState.activitiesById,
        [newId]: { id: newId, name, logs: [] }
      }
    };
    dbClient.setUserState(userState.userId, newUserState);
    setIsOpen(false);
  };
  return (
    <Root>
      {isOpen && (
        <ReactModal
          isOpen={true}
          contentLabel="Manage activities"
          style={{ content: { background: "#FFF" } }}
          onRequestClose={() => setIsOpen(false)}
        >
          <form onSubmit={handleSubmit(submitNewActivity)}>
            <input name="name" ref={register} />
            <button disabled={isSubmitting}>Add</button>
          </form>
          <form
            onSubmit={e => {
              const formData = new FormData(e.currentTarget);
              e.preventDefault();
              console.log(formData.entries());
              const newIds = [];
              for (let [inputName, value] of formData.entries()) {
                const currentIndex = parseInt(inputName.slice(6, -1));
                const newIndex = parseInt(value);
                newIds[newIndex] = userState.activityIds[currentIndex];
              }
              console.log(newIds);
              const newUserState = {
                ...userState,
                activityIds: newIds
              };
              dbClient.setUserState(userState.userId, newUserState);
              setIsOpen(false);
            }}
          >
            {userState.activityIds?.map((id, index) => {
              const activity = userState.activitiesById[id];
              return (
                <div key={id}>
                  {activity.name}{" "}
                  <input name={`index[${index}]`} defaultValue={index} />
                </div>
              );
            })}
            <button type="submit">Update orders</button>
          </form>
          {Object.entries(userState.activitiesById).map(([id, activity]) =>
            userState.activityIds.includes(id) ? null : (
              <div>
                {activity.name}{" "}
                <button
                  onClick={() => {
                    const newIds = [...userState.activityIds, id];
                    const newUserState = {
                      ...userState,
                      activityIds: newIds
                    };
                    dbClient.setUserState(userState.userId, newUserState);
                  }}
                >
                  Show
                </button>
              </div>
            )
          )}
        </ReactModal>
      )}
      {!isOpen && (
        <LinkButton
          onClick={() => setIsOpen(true)}
          data-cy="Track new activity"
        >
          + Track new activity
        </LinkButton>
      )}
    </Root>
  );
};
