import React from "react";
import styled from "styled-components";
import {
  format,
  isSameDay,
  isBefore,
  startOfDay,
  parseISO,
  addDays
} from "date-fns";
import { colours } from "../../../../styles/colours";
import { orderBy } from "lodash";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Log = styled.div`
  height: 2rem;
  width: min(2rem, 100%);
  background-color: ${colours.positive[3]};
  ${({ hasLogs }) =>
    hasLogs ? `background-color: ${colours.positive[5]};` : ""}
`;

const getDisplayedDates = logs => {
  const firstLog = orderBy(logs, "loggedAt")[0];

  if (!firstLog) {
    return [new Date()];
  }
  const dates = [parseISO(firstLog.loggedAt)];
  const isLatestDateBeforeToday = () => {
    const latestDate = dates[0];
    return isBefore(latestDate, startOfDay(new Date()));
  };
  while (isLatestDateBeforeToday()) {
    dates.unshift(addDays(dates[0], 1));
  }
  return dates;
};

export const Logs = ({ logs, name }) => {
  const displayedDays = getDisplayedDates(logs);

  return (
    <Root>
      {displayedDays.map(date => (
        <Log
          key={date.toISOString()}
          hasLogs={logs.some(({ loggedAt }) =>
            isSameDay(date, parseISO(loggedAt))
          )}
          title={`${format(date, "EEE do MMM")} - ${name}`}
          data-cy={`${name} log ${format(date, "yyyy/MM/dd")}`}
        />
      ))}
    </Root>
  );
};
