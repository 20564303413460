import styled, { css } from "styled-components";
import { colours } from "../../styles/colours";

export const buttonStyle = css`
  font-size: 24px;
  padding: 12px 32px;
  border-radius: 0;
  color: ${colours.grey[8]};;
  border: solid 1px ${colours.grey[8]};;
  background: none;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  &:hover, &:active, &:focus {
    background-color: ${colours.grey[1]};
    border-color: ${colours.grey[9]};
    color: ${colours.grey[9]};
  }
  &:active, &:focus {
    background-color: ${colours.grey[2]};
  }
`;

export const Button = styled.button`
  ${buttonStyle}
`;
