import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { updateUser } from "./userSlice";

const initialState = { isLoaded: false };

export const userStateSlice = createSlice({
  name: "userState",
  initialState: initialState,
  reducers: {
    update(state, action) {
      return {
        ...action.payload,
        isLoaded: false
      };
    }
  },
  extraReducers: {
    [updateUser]: (state, action) => {
      if (action.payload) return state;
      return initialState;
    }
  }
});

export const useUserStateSelector = () => useSelector(state => ({
  ...state.userState,
  isInitialized: !!state.userState.activityIds
}));

export const { update: updateUserState } = userStateSlice.actions;
