import React, { useState } from "react";
import styled from "styled-components";
import { Logs } from "./Logs";

const Root = styled.div`
  display: contents;
  margin: 0.5rem;
`;

const NameWrapper = styled.div``;

const ButtonAndLog = styled.div`
  grid-row: 2 / 3;
`;

const LogButtonWrapper = styled.div`
  margin: 0.5rem;
`;

const WhenInput = styled.input`
  width: 1.5rem;
`;

export const Activity = ({ activity, logActivity }) => {
  const [daysAgo, setDaysAgo] = useState(0);
  return (
    <Root>
      <NameWrapper>{activity.name}</NameWrapper>
      <ButtonAndLog>
        <LogButtonWrapper>
          <div>
            <WhenInput
              value={daysAgo}
              onChange={e => setDaysAgo(e.target.value)}
            />
          </div>
          <button
            data-cy={`Log ${activity.name}`}
            onClick={logActivity(activity.id, daysAgo)}
          >
            +
          </button>
        </LogButtonWrapper>
        <Logs {...activity} />
      </ButtonAndLog>
    </Root>
  );
};
