import React from "react";
import styled from "styled-components";
import { useUserSelector } from "../../data/userSlice";
import { useUserStateSelector } from "../../data/userStateSlice";
import { dbClient } from "../../firebase/authClient";
import { TrackNewActivity } from "./TrackNewActivity";
import { Activity } from "./Activity";
import { subDays } from "date-fns";

const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ count }) => count + 1},
    minmax(5rem, 7rem)
  );
`;

export const Activities = () => {
  const userState = useUserStateSelector();
  const user = useUserSelector();
  if (user.isSignedIn && !userState.isInitialized) return null;

  const logActivity = (id, daysAgo) => async () => {
    const newLog = {
      loggedAt: subDays(new Date(), daysAgo).toISOString()
    };
    const newUserState = {
      ...userState,
      activitiesById: {
        ...userState.activitiesById,
        [id]: {
          ...userState.activitiesById[id],
          logs: [...userState.activitiesById[id].logs, newLog]
        }
      }
    };
    dbClient.setUserState(userState.userId, newUserState);
  };
  return (
    <Root count={userState.activityIds?.length}>
      {userState.activityIds?.map(id => (
        <Activity
          key={id}
          activity={userState.activitiesById[id]}
          logActivity={logActivity}
        />
      ))}
      <TrackNewActivity />
    </Root>
  );
};
