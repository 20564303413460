import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { authClient } from "../firebase/authClient";
import { colours } from "../styles/colours";
import { useUserSelector } from "../data/userSlice";

const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const AppBar = styled.div`
  display: flex;
  min-height: 54px;
  box-shadow: 0 0.1rem 0.2rem ${colours.grey[3]},
    0 0.2rem 0.2rem ${colours.grey[1]};
`;

const appBarItemStyles = css`
  color: ${colours.grey[8]};
  font-weight: normal;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  font-size: 1rem;
  &:hover {
    background-color: ${colours.grey[2]};
  }
`;

export const AppBarItem = styled.div`
  ${appBarItemStyles}
  cursor: pointer;
`;

const AppBarButton = styled.button`
  padding: none;
  ${appBarItemStyles}
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: inherit;
`;

const AppBarLink = styled(Link)`
  ${appBarItemStyles}
  text-decoration: none;
`;

const AppBarActions = styled.div`
  display: flex;
  margin-left: auto;
  height: 100%;
`;

export const Layout = ({ children, hideActions }) => {
  const user = useUserSelector();
  if (!user.isLoaded) return null;
  return (
    <Root>
      <AppBar>
        <AppBarLink to="/">Title</AppBarLink>
        <AppBarActions>
          {!hideActions &&
            (user.isSignedIn ? (
              <>
                <AppBarItem>{user.email}</AppBarItem>
                <AppBarButton onClick={authClient.signOut}>
                  Log out
                </AppBarButton>
              </>
            ) : (
              <AppBarLink to="/auth">Log in / Register</AppBarLink>
            ))}
        </AppBarActions>
      </AppBar>
      <Content>{children}</Content>
    </Root>
  );
};
